import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['productFields', 'input', 'hidden', 'form', 'formButton']

  connect() {
    this.changeProduct();
    document.addEventListener("autocomplete.change", this.changeProduct.bind(this));
    super.connect();
  }

  changeProduct() {
    if (!this.hasInputTarget) { return }

    if ((event.detail && event.detail.value != "") && (this.hasHiddenTarget)) {
      this.hiddenTarget.value = event.detail.value;
      this.formButtonTarget.disabled = false;
    }

    var disabled;

    // Don't send hidden input to the server. Otherwise it creates a new record instead of assigning the selected one.
    var inputs = this.productFieldsTarget.getElementsByTagName('input');
    for (let input of inputs) { input.disabled = disabled; };
  }

}
