// app/javascript/controllers/panning_handler.js
import Panzoom from '@panzoom/panzoom';

export default class PanningHandler {
    constructor(controller) {
        this.controller = controller;
        this.panzoomInstances = [];
    }

    setupPanning() {
        this.controller.floorplanTargets.forEach(floorplanTarget => {
            this.panzoomInstances.push(Panzoom(
                floorplanTarget,
                {
                    disableZoom: true,
                    overflow: 'scroll',
                    handleStartEvent: (event) => {
                        event.preventDefault();
                    },
                }
            ));
        });
    }

    removePanning() {
        this.panzoomInstances.forEach(panzoomInstance => {
            panzoomInstance.destroy();
        });
        this.panzoomInstances = [];
    }
}
