import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['dealsFields', 'input', 'hidden', 'form', 'formButton']

  connect() {
    this.searchDeals();
    document.addEventListener("autocomplete.change", this.searchDeals.bind(this));
    super.connect();
  }

  searchDeals() {
    if (!this.hasInputTarget) { return }

    if (this.hiddenTarget.value == '') {
      this.formButtonTarget.disabled = true;

    } else {
      this.formButtonTarget.disabled = false;
    }
  }

}
