import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';
import { Czech } from "flatpickr/dist/l10n/cs.js"
import { Eng } from "flatpickr/dist/l10n/default.js"
import { v4 as uuidv4 } from 'uuid';

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
    this.element[this.identifier] = this; // element.controllerName anywhere you need it.
  }

  disconnect() {
    super.disconnect();
    this.removeDocumentListeners();
  }

  initialize() {
    this.documentReady(this.
    showTimesAndDatesInLocalZone.bind(this));
    this.listeners = [];
  }

  documentReady(callback) {
    // in case the document is already rendered
    if (document.readyState != 'loading') {
      callback();
      document.addEventListener('DOMContentLoaded', callback);
    }
    // modern browsers
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    // IE <= 8
    else document.attachEvent('onreadystatechange', function () {
      if (document.readyState == 'complete') callback();
    });
  }

  getLocale() {
    let locale = document.querySelector('#locale')
    if (locale == 'en') {
      locale = Eng;
    } else {
      locale = Czech;
    }
    return locale;
  }

  appendLog(message) {
    console.log(message);

    var text = document.createTextNode(message + "\n");
    if (this.hasLogdstTarget) {
      this.logdstTarget.appendChild(text);
      this.logdstTarget.scrollTop = this.logdstTarget.scrollHeight;
    }
  }

  setRequestId() {
    this.requestId = uuidv4();
    document.querySelector('#request_id').value = this.requestId;
  }

  setFormRequestId(form) {
    this.requestId = uuidv4();
    form.querySelector('#request_id').value = this.requestId;
  }

  setActionSource(actionSource) {
    document.querySelector('#action_source').value = actionSource;
  }

  replaceElementWithNewBySingleQuery(query, event) {
    let newElement = event.detail[0].body.querySelector(query);
    let oldElement = document.querySelector(query);
    oldElement.parentElement.replaceChild(newElement, oldElement);
  }

  beforeReflex(element, reflex, noop, reflexId) {
    this.appendLog("before");
  }
  reflexSuccess(element, reflex, noop, reflexId) {
    this.appendLog("success");
    this.showTimesAndDatesInLocalZone();
  }
  reflexError(element, reflex, error, reflexId) {
    this.appendLog(error);
  }
  reflexHalted(element, reflex, noop, reflexId) {
    this.appendLog("halted");
  }
  afterReflex(element, reflex, noop, reflexId) {
    this.appendLog("after");
  }
  finalizeReflex(element, reflex, noop, reflexId) {
    this.appendLog("finalize");
  }

  createSpinnerElement() {
    let spinnerWrapper = document.createElement('div')
    spinnerWrapper.classList.toggle('spinner-border');
    return spinnerWrapper;
  }

  spinnerOn(element = null) {
    if (!document.body.classList.contains('loading')) {
      document.body.classList.toggle('loading');
    }
    if (element == null) {
      var spinnerWrapper = document.querySelector('#spinner');
    } else {
      var spinnerWrapper = element;
    }
    if (spinnerWrapper) {
      if (!spinnerWrapper.classList.contains('spinner-border')) {
        spinnerWrapper.classList.toggle('spinner-border');
      }
    } else {
      this.appendLog('Missing div for spinner!')
    }
  }

  spinnerOff(element = null) {
    if (document.body.classList.contains('loading')) {
      document.body.classList.toggle('loading');
    }

    if (element == null) {
      var spinnerWrapper = document.querySelector('#spinner');
    } else {
      var spinnerWrapper = element;
    }
    if (spinnerWrapper) {
      if (spinnerWrapper.classList.contains('spinner-border')) {
        spinnerWrapper.classList.toggle('spinner-border');
      }
    } else {
      this.appendLog('Missing div for spinner!')
    }
  }

  showTimesAndDatesInLocalZone(element = null) {
    if (element == null) {
      element = document
    }
    element.querySelectorAll('[data-timestamp]').forEach(this.timeInLocalZone);
  }

  timeInLocalZone(element) {
    let dateTimeFrom = new Date(element.dataset.timestamp);
    let elementWithDate = element.querySelector('#date')
    let elementWithTime = element.querySelector('#time')
    if (elementWithDate != null) {
      elementWithDate.textContent = dateTimeFrom.toLocaleDateString(navigator.language);
    }
    if (elementWithTime != null) {
      elementWithTime.textContent = dateTimeFrom.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' });
    }
  }

  scrollToStart(query, offset = 0) {
    const element = document.querySelector(query);
    if (element) {
      const offsetTop = element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });
    }
  }

  pushQueryParamsToUrl(form) {
    const url = new URL(window.location.href)
    const formData = new FormData(form)
    const searchParams = new URLSearchParams(formData)
    url.search = searchParams.toString()
    window.history.pushState({}, '', url.toString())
  }

  addDocumentListener(event, callback) {
    document.addEventListener(event, callback);
    this.listeners.push({ event, callback });
  }

  removeDocumentListeners() {
    if (!this.listeners) return;

    this.listeners.forEach(({ event, callback }) => {
      document.removeEventListener(event, callback);
    });
    this.listeners = [];
  }
}
