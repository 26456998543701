import ApplicationController from './application_controller';

export default class extends ApplicationController {

  static targets = ['hidden', 'formButton']

  // When the content of the autocomplete is manually edited, remove the selected id from the hidden input.
  clean() {
    this.hiddenTarget.value = '';
    if (this.hasFormButtonTarget) {
      this.formButtonTarget.disabled = true;
    }
  }

}
