import ApplicationController from '../application_controller';
import Rails from "@rails/ujs"

export default class extends ApplicationController {

  static targets = ['form', 'paginationForm'];

  applyFilter(event) {
    let form = this.formTarget;
    if (this.hasFormTarget) {
      this.pushQueryParamsToUrl(this.formTarget);
    }
    this.loadFilter(event, form);
  }

  loadFilter(event, targetForm) {
    if (event != undefined) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    if (targetForm) {
      this.spinnerOn();
      this.setFormRequestId(targetForm);
      Rails.fire(targetForm, 'submit');
    } else {
      console.log('no form target');
    }
  }

  loadMore(event) {
    if (this.hasPaginationFormTarget) {
      this.paginationFormTarget.setAttribute('action', this.formTarget.getAttribute('action'));
      this.copyFormData(this.formTarget, this.paginationFormTarget);
      this.loadFilter(event,this.paginationFormTarget);
    } else {
      console.log('no pagination form target');
    }
  }

  resultNext(event) {
    let requestId = event.detail[0].querySelector('#request_id').value;
    if (event.srcElement.querySelector('#request_id').value == requestId) {
      Array.from(event.detail[0].querySelector('#list').lastElementChild.children).forEach(
        (newChild) => {
          document.querySelector('#list').lastElementChild.appendChild(newChild);
        }
      );
      document.querySelector('#pagination').innerHTML = event.detail[0].querySelector('#pagination').innerHTML;
      this.spinnerOff();
    }else {
      console.log({ message: "Old request skipping.", event: event });
    }

  }

  result(event) {
    let requestId = event.detail[0].querySelector('#request_id').value;
    if (document.querySelector('#request_id').value == requestId) {
      document.querySelector('#list').innerHTML = event.detail[0].querySelector('#list').innerHTML;
      document.querySelector('#pagination').innerHTML = event.detail[0].querySelector('#pagination').innerHTML;
    } else {
      console.log({ message: "Old request skipping.", event: event });
    }
    this.spinnerOff();
  }

  copyFormData(srcForm, targetForm) {
    const formData = new FormData(srcForm);


    for (let [name, value] of formData.entries()) {
        const field = targetForm.elements[name];
        if (field) {

            if (field.type === 'checkbox' || field.type === 'radio') {
                field.checked = value === 'on' || value === 'true';
            } else if (field.type === 'select-multiple') {
                // Pro select s více možnostmi musíme projít všechny options
                Array.from(field.options).forEach(option => {
                    option.selected = formData.getAll(name).includes(option.value);
                });
            } else {
                field.value = value;
            }
        } else {
          console.log('missing target field: ' + name + ' for value: ' + value + '');
        }
    }
  }
}
