// app/javascript/handlers/batch_movement_handler.js
export default class BatchMovementHandler {
    constructor(controller) {
        this.controller = controller;
        this.selectionBox = null;
        this.sensorsSelected = false;
        this.originalSelectionBoxPosition = null;
        this.selectedSensors = [];
        this.isDrawing = false;
        this.isMovingSensor = false;

        this.boundMarkSensorsOnMouseDown = this.markSensorsOnMouseDown.bind(this);
        this.boundMarkSensorsOnMouseMove = this.markSensorsOnMouseMove.bind(this);
        this.boundMarkSensorsOnMouseUp = this.markSensorsOnMouseUp.bind(this);

        this.boundsMoveSelectedSensorsOnMouseDown = this.moveSelectedSensorOnMouseDown.bind(this);
        this.boundsMoveSelectedSensorsOnMouseMove = this.moveSelectedSensorOnMouseMove.bind(this);
        this.boundsMoveSelectedSensorsOnMouseUp = this.moveSelectedSensorOnMouseUp.bind(this);

        this.boundCancelBatchMoveOnEscape = this.cancelBatchMoveOnEscape.bind(this);
    }

    markSensorsOnMouseDown(event) {
        this.controller.startX = event.clientX + window.scrollX;
        this.controller.startY = event.clientY + window.scrollY;
        this.isDrawing = true;

        this.selectionBox = document.createElement('div');
        this.selectionBox.style.position = 'absolute';
        this.selectionBox.style.border = '2px dashed red';
        this.selectionBox.style.pointerEvents = 'none';
        this.selectionBox.style.zIndex = '100';
        document.body.appendChild(this.selectionBox);
    }

    markSensorsOnMouseMove(event) {
        if (!this.isDrawing) return;
        this.controller.endX = event.clientX + window.scrollX;
        this.controller.endY = event.clientY + window.scrollY;

        this.selectionBox.style.left = Math.min(this.controller.startX, this.controller.endX) + 'px';
        this.selectionBox.style.top = Math.min(this.controller.startY, this.controller.endY) + 'px';
        this.selectionBox.style.width = Math.abs(this.controller.startX - this.controller.endX) + 'px';
        this.selectionBox.style.height = Math.abs(this.controller.startY - this.controller.endY) + 'px';
    }

    markSensorsOnMouseUp(event) {
        if (!this.isDrawing) return;

        this.isDrawing = false;
        this.sensorsSelected = true;

        const rect = this.selectionBox.getBoundingClientRect();

        const selectionBoxTop = parseInt(this.selectionBox.style.top);
        const selectionBoxLeft = parseInt(this.selectionBox.style.left);
        this.originalSelectionBoxPosition = { top: selectionBoxTop, left: selectionBoxLeft };
        this.sensorsInBoundary(event.currentTarget.closest('.overlay'));

        this.removeMarkSensorsHandlers();
        this.addMoveSelectedSensorsHandlers();
    }

    moveSelectedSensorOnMouseDown(event) {
        if (!this.sensorsSelected) return;

        this.controller.startX = event.clientX + window.scrollX;
        this.controller.startY = event.clientY + window.scrollY;
        this.isMovingSensor = true;
    }

    moveSelectedSensorOnMouseMove(event) {
        if (!this.sensorsSelected || !this.isMovingSensor) return;

        const deltaX = event.clientX + window.scrollX - this.controller.startX;
        const deltaY = event.clientY + window.scrollY - this.controller.startY;

        this.selectionBox.style.left = this.originalSelectionBoxPosition.left + deltaX + 'px';
        this.selectionBox.style.top = this.originalSelectionBoxPosition.top + deltaY + 'px';

        this.selectedSensors.forEach(sensor => {
            this.moveSensor(sensor, deltaX, deltaY);
        });
    }

    moveSelectedSensorOnMouseUp(event) {
        if (!this.sensorsSelected) return;
        if (!this.selectedSensors.length) {
            this.restoreInitialBatchMoveState();
            return;
        }
        this.isMovingSensor = false;
        const selectionBoxTop = parseInt(this.selectionBox.style.top);
        const selectionBoxLeft = parseInt(this.selectionBox.style.left);
        this.originalSelectionBoxPosition = { top: selectionBoxTop, left: selectionBoxLeft };
        this.selectedSensors.forEach(sensor => {
            sensor.originalPosition.x = sensor.element.dataset.x;
            sensor.originalPosition.y = sensor.element.dataset.y;
        });

        const currentOverlay = event.currentTarget.closest('.overlay');

        this.controller.spinnerOn(currentOverlay.querySelector('#spinner'));

        this.controller.overlayTargets.forEach(overlayTarget => {
            overlayTarget.style.pointerEvents = 'none';
        });

        this.controller.stimulate('PlacementReflex#batch_move', this.selectedSensors.map(sensor => {
            return {
                id: sensor.element.dataset.placementId,
                pos_x: sensor.element.dataset.x,
                pos_y: sensor.element.dataset.y,
            }
        })).then((payload) => {
            this.controller.overlayTargets.forEach(overlayTarget => {
                overlayTarget.style.pointerEvents = 'auto';
            });
            this.controller.spinnerOff(currentOverlay.querySelector('#spinner'));
        });
    }

    cancelBatchMoveOnEscape(event) {
        if (event.key === 'Escape') {
            this.controller.toggleBatchMove(event);
        }
    }

    addMarkSensorsHandlers() {
        this.controller.overlayTargets.forEach(overlayTarget => {
            overlayTarget.addEventListener('mousedown', this.boundMarkSensorsOnMouseDown);
            overlayTarget.addEventListener('mousemove', this.boundMarkSensorsOnMouseMove);
            overlayTarget.addEventListener('mouseup', this.boundMarkSensorsOnMouseUp);
        });
    }

    addMoveSelectedSensorsHandlers() {
        this.controller.overlayTargets.forEach(overlayTarget => {
            overlayTarget.addEventListener('mousedown', this.boundsMoveSelectedSensorsOnMouseDown);
            overlayTarget.addEventListener('mousemove', this.boundsMoveSelectedSensorsOnMouseMove);
            overlayTarget.addEventListener('mouseup', this.boundsMoveSelectedSensorsOnMouseUp);
        });
    }

    removeMarkSensorsHandlers() {
        this.controller.overlayTargets.forEach(overlayTarget => {
            overlayTarget.removeEventListener('mousedown', this.boundMarkSensorsOnMouseDown);
            overlayTarget.removeEventListener('mousemove', this.boundMarkSensorsOnMouseMove);
            overlayTarget.removeEventListener('mouseup', this.boundMarkSensorsOnMouseUp);
        });
    }

    removeMoveSelectedSensorsHandlers() {
        this.controller.overlayTargets.forEach(overlayTarget => {
            overlayTarget.removeEventListener('mousedown', this.boundsMoveSelectedSensorsOnMouseDown);
            overlayTarget.removeEventListener('mousemove', this.boundsMoveSelectedSensorsOnMouseMove);
            overlayTarget.removeEventListener('mouseup', this.boundsMoveSelectedSensorsOnMouseUp);
        });
    }

    sensorsInBoundary(overlay) {
        if (!this.selectionBox) return;

        const rect = this.selectionBox.getBoundingClientRect();

        this.controller.getAllPlacements(overlay).forEach(placement => {
            const placementRect = placement.getBoundingClientRect();

            if (placementRect.left >= rect.left &&
                placementRect.right <= rect.right &&
                placementRect.top >= rect.top &&
                placementRect.bottom <= rect.bottom) {
                this.selectedSensors.push({
                    element: placement,
                    originalPosition: {
                        x: placement.dataset.x,
                        y: placement.dataset.y,
                    }
                });
            }
        });
    }

    moveSensor(sensor, deltaX, deltaY) {
        const overlayTarget = sensor.element.closest('.overlay');

        const x = ((parseFloat(sensor.originalPosition.x) || 0) * overlayTarget.clientWidth) - sensor.element.offsetWidth / 2 + deltaX;
        const y = ((parseFloat(sensor.originalPosition.y) || 0) * overlayTarget.clientHeight) - sensor.element.offsetHeight / 2 + deltaY;

        sensor.element.dataset.x = (x + sensor.element.offsetWidth / 2) / overlayTarget.clientWidth;
        sensor.element.dataset.y = (y + sensor.element.offsetHeight / 2) / overlayTarget.clientHeight;
        sensor.element.style.left = 'calc(' + sensor.element.dataset.x * 100 + '%' + ' - ' + sensor.element.offsetWidth / 2 + 'px)';
        sensor.element.style.top = 'calc(' + sensor.element.dataset.y * 100 + '%' + ' - ' + sensor.element.offsetHeight / 2 + 'px)';
    }

    restoreInitialBatchMoveState() {
        this.controller.toggleBatchMoveButtonTarget.classList.remove('active');
        this.removeMarkSensorsHandlers();
        this.removeMoveSelectedSensorsHandlers();
        document.removeEventListener('keydown', this.boundCancelBatchMoveOnEscape);

        if (this.controller.panningHandler.panzoomInstances.length === 0) {
            this.controller.panningHandler.setupPanning();
        }

        if (this.selectionBox) {
            this.selectionBox.remove();
        }

        this.isDrawing = false;
        this.isMovingSensor = false;
        this.selectionBox = null;
        this.sensorsSelected = false;
        this.originalSelectionBoxPosition = null;
        this.selectedSensors = [];
    }

    prepareBatchMoveState() {
        this.controller.toggleBatchMoveButtonTarget.classList.add('active');
        this.controller.panningHandler.removePanning();
        this.addMarkSensorsHandlers();
        document.addEventListener('keydown', this.boundCancelBatchMoveOnEscape);
    }
}
