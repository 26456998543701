import ModalController from './modal_controller';

export default class extends ModalController {
  static targets = ['content'];

  showNew(event) {
    super.show(event);

    this.stimulate('CommentReflex#show_new_modal', event.currentTarget);
  }

  showEdit(event) {
    super.show(event);

    this.stimulate('CommentReflex#show_edit_modal', event.currentTarget);
  }

  showDestroy(event) {
    super.show(event);

    this.stimulate('CommentReflex#show_destroy_modal', event.currentTarget);
  }

  submitNewComment(event) {
    this.stimulate('CommentReflex#create', event.currentTarget, this.contentTarget.value)
      .then((payload) => { this.closeModaleAndUpdate(payload); });
  }

  submitUpdateComment(event) {
    this.stimulate('CommentReflex#update', event.currentTarget, this.contentTarget.value)
      .then((payload) => { this.closeModaleAndUpdate(payload); });
  }

  submitDestroyComment(event) {
    this.stimulate('CommentReflex#destroy', event.currentTarget).then(() => { this.closeModal() });
  }

  closeModaleAndUpdate(payload) {
    this.showTimesAndDatesInLocalZone(payload.event.target);
    this.closeModal();
  }
}
