// app/javascript/controllers/cursor_handler.js
export default class BatchInsertHandler {
    constructor(controller) {
        this.controller = controller;
        this.cursorElement = null;
        this.cursorListener = null;
        this.canInsertInBatch = true;
    }

    updateCursorElement(event) {
        if (!this.controller.batchInsertCheckboxTarget.checked) {
            return;
        }

        const iconScale = parseFloat(document.getElementById('floorplan-icon-scale').dataset.scale);
        const sensorsCount = parseInt(this.controller.batchInsertSensorsCountTarget.value);
        const columns = parseInt(this.controller.batchInsertColumnsTarget.value);
        const rows = Math.ceil(sensorsCount / columns);
        const rectWidth = this.controller.iconInitialWidth * iconScale * columns;
        const rectHeight = this.controller.iconInitialHeight * iconScale * rows;
        const cursor = this.cursorElement || document.createElement('div');

        cursor.style.width = Math.ceil(rectWidth) + 'px';
        cursor.style.height = Math.ceil(rectHeight) + 'px';
        cursor.style.position = 'absolute';
        cursor.style.zIndex = '100';
        cursor.style.pointerEvents = 'none';
        cursor.style.left = -100 + 'px';
        cursor.style.top = -100 + 'px';
        cursor.style.border = '2px dotted blue';
        document.body.appendChild(cursor);
        this.cursorElement = cursor;
    }

    removeCursor() {
        if (this.cursorElement) {
            this.cursorElement.remove();
            this.cursorElement = null;
        }
    }

    addCursorListener(overlayElements) {
        if (this.cursorListener) {
            return;
        }
        this.cursorListener = (e) => {
            const iconScale = parseFloat(document.getElementById('floorplan-icon-scale').dataset.scale);
            this.cursorElement.style.left = e.clientX + window.scrollX - (this.controller.iconInitialWidth * iconScale / 2) + 'px';
            this.cursorElement.style.top = e.clientY + window.scrollY - (this.controller.iconInitialHeight / 2 * iconScale) + 'px';
            this.cursorFitOverlay(e);
        };

        overlayElements.forEach(overlayElement => overlayElement.addEventListener('mousemove', this.cursorListener));
    }

    cursorFitOverlay(e) {
        const overlay = e.currentTarget.closest('.overlay');
        const overlayRect = overlay.getBoundingClientRect();
        const cursorRect = this.cursorElement.getBoundingClientRect();
        const maxX = e.clientX + cursorRect.width - this.controller.iconInitialWidth / 2;
        const maxY = e.clientY + cursorRect.height - this.controller.iconInitialHeight / 2;
        const minX = e.clientX - this.controller.iconInitialWidth / 2;
        const minY = e.clientY - this.controller.iconInitialHeight / 2;

        if (minX > overlayRect.x && maxX < overlayRect.x + overlayRect.width &&
            minY > overlayRect.y && maxY < overlayRect.y + overlayRect.height) {
            this.cursorElement.style.border = '2px dotted blue';
            this.canInsertInBatch = true;
            return true;
        } else {
            this.cursorElement.style.border = '2px dotted red';
            this.canInsertInBatch = false;
            return false;
        }
    }

    removeCursorListener(overlayElements) {
        overlayElements.forEach(overlayElement => overlayElement.removeEventListener('mousemove', this.cursorListener));
        this.cursorListener = null;
    }
}
