import ApplicationController from './application_controller';
import pdfjsLib from "pdfjs-dist/webpack";
import tippy, {hideAll} from "tippy.js";
import Panzoom from '@panzoom/panzoom';
import {v4 as uuidv4} from 'uuid';
import PanningHandler from "../handlers/panning_handler";
import BatchInsertHandler from "../handlers/batch_insert_handler";
import BatchMovementHandler from "../handlers/batch_move_handler";

export default class extends ApplicationController {
    static targets = [
        "canvas",
        "zoomIn",
        "zoomOut",
        "overlay",
        "toggleButton",
        "floorplan",
        "batchNamePatternLegend",
        "batchInsertOptions",
        "batchInsertCheckbox",
        "batchInsertSensorsCount",
        "batchInsertNamePattern",
        "batchInsertRows",
        "batchInsertColumns",
        "toggleBatchMoveButton",
    ];

    initialize() {
        this.scaleStep = 0.25;
        this.toggledProductId = null;
        this.debounceTimer = null;
        this.iconScaleStep = 0.05;
        this.iconInitialWidth = 60;
        this.iconInitialHeight = 60;

        this.panningHandler = new PanningHandler(this);
        this.batchInsertHandler = new BatchInsertHandler(this);
        this.batchMoveHandler = new BatchMovementHandler(this);

        this.canvasTargets.forEach(canvasTarget => {
            this.renderPage(canvasTarget, 1.0);
        })

        for (const button of this.toggleButtonTargets) {
            tippy(button, {
                content: button.dataset.installationNotes,
            });
        }

        tippy(this.batchNamePatternLegendTarget, {
            content: this.batchNamePatternLegendTarget.dataset.tooltip,
        });

        this.batchMoveHandler.restoreInitialBatchMoveState();
    }

    disconnect() {
        this.panningHandler.removePanning();
    }

    getAllPlacements(floorplanWrapper) {
        return floorplanWrapper.querySelectorAll('.point.draggable');
    }

    getActiveFloorplanId() {
        let currentTab = document.querySelector('.controls ul li.active');
        return currentTab.getAttribute('data-tab-target').replace("floorplan-", "");
    }

    removeActiveStylesFromProductButtons() {
        for (let button of this.toggleButtonTargets) {
            button.style.color = button.dataset.color;
            button.style.backgroundColor = 'white';
            button.style.backgroundImage = `url(${button.dataset.backgroundImage})`;
            this.overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `auto`;});
        }
    }

    toggle(e) {
        const proposalLocked = document.querySelector('input[name="proposal_locked"]')?.checked
        if (proposalLocked) {
            return;
        }
        this.removeActiveStylesFromProductButtons();
        if (this.toggledProductId === event.target.dataset.productId) {
            this.toggledProductId = null;
            this.batchInsertHandler.removeCursor()
            this.batchInsertHandler.removeCursorListener(this.overlayTargets);
        } else {
            this.toggledProductId = event.target.dataset.productId;
            event.target.style.color = "white";
            event.target.style.backgroundColor = event.target.dataset.color;
            event.target.style.backgroundImage = `url(${event.target.dataset.inverseBackgroundImage})`;
            this.overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `url(${event.target.dataset.cursorImage}) 32 32, auto`; });

            if(this.batchInsertCheckboxTarget.checked) {
                this.batchInsertHandler.updateCursorElement(e);
                this.batchInsertHandler.addCursorListener(this.overlayTargets);
            }

            hideAll();
        }
    }


    activateProductButton(productButton) {
        productButton.style.color = "white";
        productButton.style.backgroundColor = productButton.dataset.color;
        productButton.style.backgroundImage = `url(${productButton.dataset.inverseBackgroundImage})`;
    }

    add(event) {
        if (this.toggledProductId === null || this.overlayTarget.disabled === true) {
            return;
        }

        if(this.batchInsertCheckboxTarget.checked && !this.batchInsertHandler.canInsertInBatch) {
            return;
        }

        this.overlayTargets.forEach(overlayTarget => { overlayTarget.disabled = true; });
        const spinnerElement = event.currentTarget.querySelector('#spinner');
        this.spinnerOn(spinnerElement);
        var rect = event.target.getBoundingClientRect();
        var x = (event.clientX - rect.left) / event.target.offsetWidth;
        var y = (event.clientY - rect.top) / event.target.offsetHeight;
        var currentTab = document.querySelector(".controls ul li.active");
        var placementId = uuidv4();
        var busId = document.querySelector("#busable_id").value;
        let controller = this;
        let placementNumber = document.querySelector("#placement_number").value;
        let overlayTargets = this.overlayTargets;
        let productId = this.toggledProductId;

        let scaleElement = document.getElementById('floorplan-icon-scale');
        let scaleTo = parseFloat(scaleElement.dataset.scale) + this.iconScaleStep;

        const rowGap = this.iconInitialHeight / event.target.offsetHeight;
        const columnGap = this.iconInitialWidth / event.target.offsetWidth;

        const batchInsertOptions = {
            batch_insert: this.batchInsertCheckboxTarget.checked,
            sensors_count: parseInt(this.batchInsertSensorsCountTarget.value),
            name_pattern: this.batchInsertNamePatternTarget.value,
            scaling_factor: scaleTo,
            row_gap: rowGap,
            column_gap: columnGap,
            insert_options: {
                rows: parseInt(this.batchInsertRowsTarget.value),
                columns: parseInt(this.batchInsertColumnsTarget.value),
            },
        };

        this.stimulate("PlacementReflex#add", event.target, busId, productId, x, y, placementNumber, batchInsertOptions)
      .then(payload => {
        this.batchInsertCheckboxTarget.checked = false
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);

        if (!document.querySelector('input[id="floorplan-editor-add_batch"]').checked) {
          controller.removeActiveStylesFromProductButtons();
        } else {
          let productDiv = document.querySelector('button[data-product-id="' + productId + '"]');
          controller.toggledProductId = productId;
          controller.activateProductButton(productDiv);
          overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `url(${productDiv.dataset.cursorImage}) 32 32, auto`;});
        }

                // TODO ugly same code is in tabs_controller
                document.querySelectorAll('.controls ul li').forEach(
                    function (tabSelector) {
                        if (tabSelector.dataset.tabTarget === currentTab.dataset.tabTarget) {
                            tabSelector.classList.add('active');
                        } else {
                            tabSelector.classList.remove('active');
                        }
                    }
                );

                document.querySelectorAll('.placements').forEach(
                    function (floorplan) {
                        if (floorplan.id === currentTab.dataset.tabTarget) {
                            floorplan.style.display = currentTab.dataset.display || 'block';
                        } else {
                            floorplan.style.display = 'none';
                        }
                    }
                );

                var addedPoint = document.getElementById(`point-${placementId}`);
                addedPoint._tippy.show();
            })
            .catch(payload => {
                const failed_event = new CustomEvent('save-indicator:failed', {detail: payload});
                window.dispatchEvent(failed_event);
            })
            .finally(() => {
                overlayTargets.forEach(overlayTarget => { overlayTarget.disabled = false; });
                this.spinnerOff(spinnerElement);
                this.batchInsertHandler.removeCursorListener(this.overlayTargets);
            });
    }

    zoomIn(event) {
        this.zoomInTarget.disabled = true;
        this.zoomOutTarget.disabled = true;
        let placementsDiv = event.currentTarget.closest('.placements')
        let scaleStep = parseFloat(document.querySelector('#scale_step').value);
        let scale = parseFloat(placementsDiv.querySelector('#floorplan-scale').dataset.scale);
        scale = scale + scaleStep;
        placementsDiv.querySelector('#floorplan-scale').dataset.scale = scale;
        let floorplanId = placementsDiv.id.replace('floorplan-', '');
        console.log(scale);
        this.renderPage(placementsDiv.querySelector('#canvas-' + floorplanId), scale);
    }

    zoomOut() {
        this.zoomInTarget.disabled = true;
        this.zoomOutTarget.disabled = true;
        let placementsDiv = event.currentTarget.closest('.placements')
        let scaleStep = parseFloat(document.querySelector('#scale_step').value);
        let scale = parseFloat(placementsDiv.querySelector('#floorplan-scale').dataset.scale);
        if (scale - scaleStep > 0) {
            scale -= scaleStep;
            placementsDiv.querySelector('#floorplan-scale').dataset.scale = scale;
            let floorplanId = placementsDiv.id.replace('floorplan-', '');
            console.log(scale);
            this.renderPage(placementsDiv.querySelector('#canvas-' + floorplanId), scale);
        } else {
            this.zoomInTarget.disabled = false;
        }
    }

    increaseIconSize() {
        let scaleElement = event.currentTarget.closest('#floorplan-icon-scale');
        let floorplanId = scaleElement.closest('.placements').id.replace("floorplan-", "");
        let scaleTo = parseFloat(scaleElement.dataset.scale) + this.iconScaleStep;
        scaleElement.dataset.scale = scaleTo
        this.resetAndStartDebounceTimer(scaleElement.dataset.scale, floorplanId);
        this.scaleFloorplanPlacements(scaleTo, floorplanId);
    }

    decreaseIconSize() {
        let scaleElement = event.currentTarget.closest('#floorplan-icon-scale');
        let floorplanId = scaleElement.closest('.placements').id.replace("floorplan-", "");
        let scaleTo = parseFloat(scaleElement.dataset.scale) - this.iconScaleStep;
        scaleElement.dataset.scale = scaleTo
        this.resetAndStartDebounceTimer(scaleElement.dataset.scale, floorplanId);
        this.scaleFloorplanPlacements(scaleTo, floorplanId);
    }

    scaleFloorplanPlacements(scale, floorplanId) {
        let floorplanWrapper = document.querySelector('#floorplan-' + floorplanId).querySelector('.floorplan .wrapper');
        let placementsWithActiveFloorplan = this.getAllPlacements(floorplanWrapper);

        placementsWithActiveFloorplan.forEach(placement => {
            let x_size = placement.textContent.trim().length * 6 * scale;
            let place_x_size = x_size * 1.4;
            placement.style['width'] = place_x_size + 'px';
            placement.style['min-width'] = place_x_size + 'px';
            placement.style['background-size'] =  floorplanWrapper.dataset.iconSizeWidth  * scale + 'px ' +floorplanWrapper.dataset.iconSizeHeight  * scale  + 'px';
            placement.style['left'] = 'calc(' + placement.dataset.x * 100 + '% - ' + parseFloat(floorplanWrapper.dataset.iconSizeOuterWidth)  * scale / 2 + 'px)';
            placement.style['top'] = 'calc(' + placement.dataset.y * 100 + '% - ' + parseFloat(floorplanWrapper.dataset.iconSizeHeight) * scale / 2 + 'px)';
            placement.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
            let sensor = placement.querySelector('.sensor');

            if (sensor ) {
                sensor.style['font-size'] = floorplanWrapper.dataset.iconSizeFontSize * scale + 'px';
                sensor.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
                sensor.style['line-height'] = sensor.style['height'];
                sensor.style['border-radius'] = 10 * scale + 'px';
                sensor.style['top'] = 'calc(-20px - '  + (floorplanWrapper.dataset.iconSizeHeight * scale - floorplanWrapper.dataset.iconSizeHeight) + 'px)';
            } else {
                let centralUnit = placement.querySelector('.central-unit');
                centralUnit.style['font-size'] = floorplanWrapper.dataset.iconSizeFontSize * scale + 'px';
                centralUnit.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
                centralUnit.style['line-height'] = centralUnit.style['height'];
                centralUnit.style['width'] = floorplanWrapper.dataset.iconSizeWidth * scale * 2 + 'px';
                centralUnit.style['border-radius'] = 10 * scale + 'px';

            }
        });
    }

    resetAndStartDebounceTimer(currentScale) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => { this.changeIconSize(currentScale); }, 300);
    }

    changeIconSize(scale) {
        this.stimulate('PlacementReflex#update_sensors', {
                scale: scale,
                floorplan_id: this.getActiveFloorplanId()
            }
        ).then((payload) => {
            console.log("Scale icons saved for floorplan ")
        })

    }

    renderPage(canvas, scale) {
        // display: 'none' implies no size and thus the element can be positioned via interactjs
        $('.point').css('visibility', 'hidden');
        var controller = this;

        var loadingTask = pdfjsLib.getDocument(canvas.dataset.fileUrl);
        loadingTask.promise.then(function (pdf) {
            var page = pdf.getPage(1);
            page.then(function (page) {
                var viewport = page.getViewport({scale: scale});

                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                var renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                    $('.point').css('visibility', 'visible');
                    controller.zoomInTarget.disabled = false;
                    controller.zoomOutTarget.disabled = false;
                });
            });
            document.querySelector('.floorplan').style = '';
        });
    }

    fullScreen(event) {
        document.querySelector("body > main > div.container").classList.toggle('fullscreen');
        document.querySelectorAll(".floorplan").forEach(function (floorplan) {
            floorplan.classList.toggle('fullscreen');
        });
    }

    updateCursorElement(e) {
        this.batchInsertHandler.updateCursorElement(e);
    }

    toggleBatchInsertOptions(e) {
        if(this.batchInsertCheckboxTarget.checked) {
            this.batchInsertOptionsTarget.classList.remove('hidden');
            this.batchInsertHandler.updateCursorElement(e);
            this.batchInsertHandler.addCursorListener(this.overlayTargets);
        } else {
            this.batchInsertOptionsTarget.classList.add('hidden');
            this.batchInsertHandler.removeCursorListener(this.overlayTargets);
            this.batchInsertHandler.removeCursor();
        }
    }

    toggleBatchMove(e) {
        this.toggleBatchMoveButtonTarget.classList.contains('active') ?
            this.batchMoveHandler.restoreInitialBatchMoveState() : this.batchMoveHandler.prepareBatchMoveState();
    }
}
