import ApplicationController from '../application_controller';

export default class extends ApplicationController {
    static targets = ['emailText', 'approvedInputsSection', 'startAt', 'endAt', 'customerType',
        'activeSubscriptionEndAt', 'customerContactedAt', 'durationInYears', 'subscriptionTypeId']

    statusChanged(event) {
        const status = event.target.value;
        const emailText = this.emailTextTarget;
        emailText.innerHTML = emailText.dataset[`${status}EmailText`];
        emailText.parentElement.classList.remove('hidden');
        const displayApprovedInputs = status === 'approve';
        this.setStartAt(status);
        this.setEndAt(status);
        this.approvedInputsSectionTarget.style.display = displayApprovedInputs ? 'block' : 'none';

    }

    customerChanged(event) {
        this.customerTypeTarget.value = event.target.selectedOptions[0].dataset.customerType;
    }

    setStartAt(status) {
        const startAt = this.startAtTarget;
        if (status !== 'approve' || startAt.value) return

        const activeSubscriptionEndAt = this.activeSubscriptionEndAtTarget.value || null;
        const customerContactedAt = this.customerContactedAtTarget.value || null;

        if (!activeSubscriptionEndAt && !customerContactedAt) return

        const activeSubscriptionEndAtDate = new Date(activeSubscriptionEndAt);
        const customerContactedAtDate = new Date(customerContactedAt);
        const startAtDate = customerContactedAtDate > activeSubscriptionEndAtDate ? customerContactedAtDate : activeSubscriptionEndAtDate

        this.startAtTarget._flatpickr.setDate(startAtDate);
    }

    setEndAt(status) {
        const endAt = this.endAtTarget;
        const startAt = this.startAtTarget.value;
        const durationInYears = this.durationInYearsTarget.value;

        if (status !== 'approve' || endAt.value || !startAt || !durationInYears) return

        const startAtDate = new Date(startAt);
        startAtDate.setDate(startAtDate.getDate() - 1);
        startAtDate.setFullYear(startAtDate.getFullYear() + parseInt(this.durationInYearsTarget.value, 10));
        this.endAtTarget._flatpickr.setDate(startAtDate);
    }

    calculateDiscount() {
        if (!this.subscriptionTypeIdTarget.value || !this.durationInYearsTarget.value) return

        this.stimulate('Billing::SubscriptionReflex#calculate_discount',
            this.subscriptionTypeIdTarget.value,
            this.durationInYearsTarget.value)
    }
}